.uploadImgSection {
  width: 43.75rem;
  height: 23.75rem;
  background-color: #d9d9d966;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
}
.uploadImgSection label {
  font-family: Lexend;
  font-size: 30px;
  font-weight: 300;
  text-align: center;
  cursor: pointer;
}
.uploadImgIconWrapper {
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.logoTxtClass {
  width: 80.563rem;
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  margin-top: 25px;
  padding-left: 30px;
}
.logoTxtClass h2 {
  font-family: Lexend;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
}

.allowQuickBookingSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #000;
  margin: 2rem 6.25rem 3rem;
  width: 93%;
}
.leftTxtClass h4 {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}
.rightCheckboxClass input {
  display: none;
}

.rightCheckboxClass label {
  position: relative;
  display: block;
  width: 100px;
  height: 37px;
  background-color: #3384b1;
  /* border: 1.5px solid; */
  border-radius: 50px;
  cursor: pointer;
  transition: background 300ms linear;
}
.rightCheckboxClass label:before {
  position: absolute;
  top: 18px;
  left: 0px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 37px;
  height: 37px;
  background-color: #e0e0e0;
  border-radius: 50%;
  transition: left 500ms linear;
}

.rightCheckboxClass input:checked + label {
  background-color: #fff;
  border: 1.5px solid #3384b1;
}

.rightCheckboxClass input:checked + label:before {
  top: 18px;
  left: 62px;
  background-color: #e0e0e0;
  /* border: 1.5px solid #000; */
}

.toggleAllowText {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  /* background-color: #3384b1;
    border-radius: 50px; */
}
.toggleAllowText span {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}
.toggleAllowText span:nth-child(1) {
  color: #3384b1;
}
/* .toggleAllowText span:nth-child(2){
    color: #fff;
   
  } */
.quickbkngBtnWrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 4rem;
}

.quickbkngBtnWrapper button {
  width: 14.688rem;
  height: 3.813rem;
  background-color: #3384b1;
  font-family: Lexend;
  font-size: 30px;
  font-weight: 400;
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}
.uploadImgIconWrapper img {
  width: 50%;
}
.logoTxtClass input {
  width: 80.563rem;
  height: 4.938rem;
  outline: none;
  border: none;
  background-color: #d9d9d9;
  font-family: Lexend;
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
}
.inputTxtClass {
  width: 82.563rem;
  height: 4.938rem;
  margin: auto;
}
.inputTxtClass textarea {
  width: 82.3rem;
  height: 4.938rem;
  margin-top: 25px;
  font-family: Lexend;
  font-size: 18px;
  resize: none;
}

@media only screen and (min-width: 1512px) and (max-width: 1512px) and (min-height: 892px) and (max-height: 892px) {
  .allowQuickBookingSection {
    margin: 4rem 6.25rem 3rem;
  }
}

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1440px) {
  .inputTxtClass {
    width: 93%;
  }
  .inputTxtClass textarea {
    width: 100%;
  }
  .logoTxtClass {
    width: 57rem;
  }
}
@media only screen and (max-width: 1280px) {
  .uploadImgSection {
    width: 30rem;
    height: 20.75rem;
  }

  .logoTxtClass {
    width: 46rem;
  }
  .logoTxtClass input {
    font-size: 25px;
  }
  .allowQuickBookingSection {
    margin: 2rem 3rem 3rem;
  }
  .quickbkngBtnWrapper {
    margin-bottom: 3rem;
  }
}

@media only screen and (max-width: 1025px) {
  .uploadImgSection {
    width: 28rem;
  }
  .logoTxtClass {
    width: 40rem;
  }
  .inputTxtClass {
    width: 41.563rem;
  }
  .inputTxtClass textarea {
    width: 41.5rem;
  }
}

@media only screen and (max-width: 450px) {
  .inputTxtClass {
    /* display: none; */
    width: 19rem;
  }
  .inputTxtClass textarea {
    width: 18.65rem;
  }

  .flexForm {
    height: 75%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .uploadImgSection {
    width: 93%;
    height: 203px;
  }

  .uploadImgIconWrapper img {
    width: 72px;
    height: 66px;
  }

  .uploadImgSection label {
    font-size: 20px;
  }

  .logoTxtClass {
    width: 85%;
    height: 49px;
    background-color: #d9d9d9;
  }

  .logoTxtClass input {
    height: 47px;
    font-size: 20px;
    background-color: #d9d9d9;
  }

  .leftTxtClass h4 {
    font-weight: 300;
    font-size: 18px;
  }

  .quickbkngBtnWrapper {
    height: 100px;
    display: flex;
    align-items: flex-end;
  }

  .quickbkngBtnWrapper button {
    width: 155px;
    height: 40px;
    font-size: 20px;
  }

  .allowQuickBookingSection {
    /* width: 85%; */
    margin: 0px;
    margin: 20px;
  }

  /* .quickbkngBtnWrapper{
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  .rightCheckboxClass {
    display: block;
  }

  .rightCheckboxClass label {
    width: 80px;
    height: 30px;
  }

  .rightCheckboxClass label:before {
    top: 15px;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    transition: left 500ms linear;
  }

  .rightCheckboxClass input:checked + label:before {
    top: 15px;
    left: 49px;
  }
}
@media only screen and (max-width: 412px) {
  .inputTxtClass textarea {
    width: 23.4rem;
  }
  .inputTxtClass {
    width: 24rem;
  }
}
