.EventViewWrapper {
  height: 64%;
  display: flex;
  padding-top: 1rem;
  flex-direction: column;
}

.create_eventViewWrapper {
  height: 69%;
  display: flex;
  padding-top: 4rem;
  justify-content: center;
  position: relative;
}

.EventNavbarSection {
  display: flex;
  justify-content: flex-end;
  padding-right: 10rem;
}

.event_image_container {
  height: 52%;
}

.card_view>span {
  font-weight: 700;
  color: #3384b1;
}

.create-event-form-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.create-event-image-upload-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

}

.create-event-image-upload-container>label {
  width: 99%;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
}

.createNewButton {
  font-family: Inter;
  font-size: 20px;
  font-weight: 400;
  background-color: #3384b1;
  color: #fff;
  width: 150px;
  display: flex;
  justify-content: center;
  padding: 8px 0;
  border-radius: 5px;
  border: none;
  align-items: center;
}

.event_form_field {
  width: 99%;
  height: 60px;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;
}

.event_form_input_field {
  height: 59px;
  width: 48%;
  border-radius: 4px;
  border: 1px solid #000000;
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  padding-left: 20px;
  background-color: inherit;
}

.event_form_input_field:focus {
  outline: none;
}

.publish_button_container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
}

.event_form_input_field::placeholder {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.form_input_fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 51%;
  width: 100%;
  gap: 2rem;
  justify-content: center;
  align-items: center;
}

.create_event_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 3rem 1rem;
  background-color: #f4f4f4;
  width: 73%;
  height: max-content;
  border-radius: 6px;
}

.create_event_form>label {
  font-family: "LEXEND";
  width: 85%;
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
}

.file_button_wrapper {
  width: 6rem;
  border: 1px solid #000000;
  border-radius: 4px;
  height: 2rem;
  cursor: pointer;
  position: relative;
  background-color: inherit;
  /* Set background color to inherit */
}

.file_button_wrapper>input {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
  /* Ensure the input is on top */
  background-color: inherit;
}

.file_button_wrapper>span {
  position: absolute;
  left: 22%;
  top: 22%;
  z-index: 0;
  color: #000000;
  /* Ensure text color is visible */
}

.event_publish_button {
  width: 198px;
  height: 55px;
  top: 783px;
  left: 862px;
  gap: 0px;
  border-radius: 5px;
  border: none;
  background-color: #3384b1;
  color: #ffffff;
  font-family: Lexend;
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  text-align: center;
}

.events {
  height: 57%;
  width: 95%;
  display: flex;
  gap: 3rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3rem;
  margin-left: 3rem;
  cursor: pointer;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.no_events {
  width: 100%;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  font-family: Lexend;
  font-size: 32px;
  font-weight: 300;
  line-height: 67.5px;
  text-align: center;
}

.event_card {
  width: 22.361rem;
  border: 1px solid;
  height: 20rem;
  border-radius: 10px;
  position: relative;
}

.event_image {
  display: block;
  width: 100%;
  height: 175px;
  border-radius: 10px;
}

.event_name {
  position: absolute;
  left: 10%;
  top: 50%;
  font-family: Lexend;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  text-align: left;
  color: #ffffff;
}

.eventDetailsWrapper {
  height: 60%;
  display: flex;
  align-items: center;
  margin-top: 3rem;
  position: relative;
  gap: 3%;
  margin-left: 2vw;
}

.eventDetailsWrapper>.navigate-svg-container {
  height: 78%;
}

.event_details_card {
  height: 20em;
  width: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: #ffffff;
}

.event_details_cards {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7rem;
}

.event_detail_name {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
  line-height: 35px;
  text-align: center;
}

.event_detail_count {
  font-family: Lexend;
  font-size: 72px;
  font-weight: 400;
  line-height: 206.25px;
  text-align: center;
}

.event_details_container {
  width: 95%;
  background-color: #f4f4f4;
  display: flex;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  padding: 4rem 0rem;
  gap: 2.5rem;
}

.event_details_header {
  font-family: Lexend;
  font-size: 32px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: center;
}

.event_capacity {
  font-family: Lexend;
  font-size: 28px;
  font-weight: 400;
  line-height: 43.75px;
  text-align: center;
}

.kebab_menu {
  position: absolute;
  top: 15px;
  right: 20px;
}

.image_overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 175px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  /* Semi-transparent black */
  pointer-events: none;
  /* Allows clicks to pass through the overlay */
}

.event_card_dropdown {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  width: 40%;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 41px;
  background-color: #ffffff;
}

.event_card_dropdown>div {
  font-family: Inter;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  text-align: center;
  color: #000000;
  border-bottom: 1px solid;
  width: 100%;
  padding: 5px 0px;
}

.event_card_dropdown>div:nth-child() {
  border-bottom: none;
}

.card_event_name {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: #000000;
}

.card_event_name>span {
  font-weight: 400;
}

.card_event_date,
.card_event_time,
.card_event_capacity {
  font-family: Lexend;
  font-size: 14px;
  font-weight: 300;
  line-height: 22.5px;
  text-align: left;
  color: #000000;
}

.card_event_date>span,
.card_event_time>span,
.card_event_capacity>span {
  font-weight: 400;
}

.event_card_details {
  padding-top: 0.25rem;
  padding-left: 1rem;
}

.card_view {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-right: 1.5rem;
  gap: 0.25rem;
  font-family: Lexend;
  font-size: 18px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: left;
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .event_form_field {
    width: 100%;
  }

  .event_form_input_field {
    width: 100%;
  }

  .event_publish_button {
    width: max-content;
    height: max-content;
    font-size: 20px;
    padding: 0.35rem 1rem;
  }
}

@media screen and (min-width: 1397px) and (max-width: 1557px) {
  .event_form_input_field {
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .event_form_input_field {
    width: 100%;
  }
}