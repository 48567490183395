.updatePolicyForm {
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.updatePolicyForm>form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.updatePolicyFields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.updatePolicyField {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.updatePolicyField label {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 16px;
  opacity: 70%;
}

.updatePolicyField input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: 1px solid black;
  border-radius: 4px;
  font-size: 16px;
}

.updatePolicyField.file {
  position: relative;
}

#updatePolicyFile {
  opacity: 0;
  width: 800px;
  height: 212%;
  cursor: pointer;
  z-index: 100000000;
}

#updatePolicyFile+.uploadPolicy {
  border: 1px solid black;
  width: 22%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 40%;
  z-index: 0;
}

.uploadPolicy.update {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 40%;
  left: 0%;
  z-index: 0;
  width: 174px;
  height: 55px;
  border: 1px solid black;
  border-radius: 10px;
}

.uploadPolicy>span.uploadButton {
  font-size: 16px;
  font-family: "Lexend";
  font-weight: 400;
  display: flex;
  gap: 10px;
}

.update-policy-button {
  width: 20%;
  height: 32px;
  background-color: #3384b1;
  border: none;
  color: white;
  display: flex;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  font-family: "Lexend";
  border-radius: 6px;
}

.update-policy-button:hover {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.uploaded-file-details {
  margin-top: 5px;
  font-size: 14px;
  font-family: "Lexend";
  font-weight: 400;
  color: green;
}

.publishPolicy-btn {
  width: auto;
  height: 40px;
  background: #3384b1;
  border: none;
  color: white;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Lexend";
  font-size: 16px;
}

.policy_toggle_label {
  font-family: "Lexend";
  font-weight: 400;
  font-size: 16px;
  opacity: 70%;
}

.visibilityToggle {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dialogHeader {
  font-size: 20px;
  font-family: "Lexend";
  font-weight: 500;
}

/* #update_policy_dialog {
} */

.p-dialog-mask.p-dialog-center.p-component-overlay.p-component-overlay-enter.p-dialog-draggable.p-dialog-resizable {
  background: rgba(0, 0, 0, 0.5);
}

.updatePolicyField.file .uploadPolicy.update {
  top: 113%;
}

.visContainer {
  margin-top: 4em;
}

.updatePolicy-btn {
  width: 20%;
  height: 10%;
  background: #3384b1;
  border: none;
  color: white;
  padding: 1.3% 2%;
  display: flex;
  justify-content: center;
  gap: 2%;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Lexend";
  font-size: 18px;
  margin: auto;
}

.p-dialog-header {
  background: #3384b1;
  display: flex;
  height: 50px;
  font-size: 24px;
  font-family: "Lexend";
  color: wheat;
  align-items: center;
  justify-content: center;
}

.p-dialog-title {
  background: #3384b1;
  display: flex;
  height: auto;
  font-size: 24px;
  font-family: "Lexend";
  color: wheat;
  align-items: center;
  justify-content: center;
}

.closeDialog {
  position: absolute;
  top: 1%;
  right: 1%;
}


.p-dialog-header-icon.p-dialog-header-close.p-link {
  visibility: hidden;
}

.p-dialog p-dialog-header-icons {
  visibility: hidden;
}