.addNewRoomWapper {
  width: 100%;
  position: relative;
  font-family: 'Nunito';
}

.addNewRoomWapper > svg {
  position: absolute;
  left: 2%;
}


.addNewFormWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  margin: 70px 180px 45px;
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.addNewRoomWapper h2 {
  font-family: Nunito;
  font-size: 28px;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: capitalize;
  color: black;
}
.fileInput {
  width: 850px;
  height: 170px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #3384b1;
}
.fileInput label {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #3384b1;
  width: 170px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}

.addNewFormInputWrapper input {
  width: 845px;
  height: 50px;
  border-radius: 5px;
  /* border: none; */
  margin: 5px 0 10px;
  font-family: "Poppins";
  border: 1px solid #3384b1;
  padding-left: 8px;
  font-size: 16px;
  font-family: "Inter";
}
.addNewFormInputWrapper label {
  font-family: Inter;
  font-size: 18px;
  font-weight: 500;
  /* color: #fff; */
  color: #3384b1;
}
.addNewFormInputWrapper textarea {
  width: 52.8rem;
  height: 9.875rem;
  border-radius: 5px;
  /* border: none; */
  padding: 5px 2px;
  font-family: "Poppins";
  margin: 5px 0px;
  font-size: 14px;
  resize: none;
  border: 1px solid #3384b1;
}

.react-select-container {
  z-index: 99 !important;
  border: 1px solid #3384b1;
  border-radius: 5px;
}

.css-b62m3t-container {
  position: static;
}

.descheight input {
  height: 160px;
  margin-top: 10px;
}
.setQuickBookingSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
}
.leftClass h4 {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
}
.setQuickBookingSection > .leftClass > h4 {
  color: #3384b1 !important;
}
.addBtnWrapper {
  text-align: center;
  margin: 35px 0 20px;
  display: flex;
  justify-content: center;
  gap: 5%;
}
.addBtnWrapper button {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
  background-color: #3384b1;
  width: 160px;
  height: 45px;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 4px 0px #00000040;
  cursor: poNunito;
  text-transform: capitalize;
}

.rightClass input {
  display: none;
}

.rightClass label {
  position: relative;
  display: block;
  width: 100px;
  height: 37px;
  background-color: #3384b1;
  /* border: 1.5px solid; */
  border-radius: 50px;
  cursor: pointer;
  transition: background 300ms linear;
}

.label-disable {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 500;
  color: #3384b1;
}

.rightClass label:before {
  position: absolute;
  top: 18px;
  left: 1px;
  transform: translateY(-50%);
  content: "";
  display: block;
  width: 37px;
  height: 37px;
  background-color: #e0e0e0;
  border-radius: 50%;
  transition: left 500ms linear;
}

.rightClass input:checked + label {
  background-color: #000;
  /* border: 1.5px solid #000; */
}

.rightClass input:checked + label:before {
  top: 18px;
  left: 62px;
  /* border: 1.5px solid #000; */
}
.toggleText {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}
.toggleText span {
  font-family: Nunito;
  font-size: 18px;
  font-weight: 400;
}
.toggleText span:first-child {
  color: #fff;
}
.imgNameList {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
ul.imgNameList li {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.showFileDetails {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.indexClass {
  z-index: -1 !important;
}
.descheight {
  margin-top: 10px;
}

/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .addNewFormWrapper {
    margin: 70px 60px 45px;
  }
  .fileInput {
    width: 48rem;
  }
  .addNewFormWrapper form {
    width: 48rem;
  }
  .addNewFormInputWrapper input {
    width: 48rem;
  }
  .addNewFormInputWrapper textarea {
    width: 47.8rem;
  }
}

@media only screen and (max-width: 1025px) {
  .fileInput {
    width: 42rem;
  }
  .addNewFormWrapper form {
    width: 42rem;
  }
  .addNewFormInputWrapper input {
    width: 42rem;
  }
  .addNewFormInputWrapper textarea {
    width: 41.8rem;
  }
}

@media only screen and (max-width: 450px) {
  .addNewRoomWapper {
    width: 100vw;
    display: flex;
    justify-content: center;
  }

  .addNewFormInputWrapper textarea {
    width: 98%;
  }

  .addNewRoomWapper h2 {
    font-size: 18px;
    font-weight: 400;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .fileInput {
    width: 99%;
    height: 14vh;
  }

  .fileInput label {
    height: 25px;
    width: 114px;
    font-size: 16px;
    font-weight: 300px;
  }

  .addNewFormWrapper {
    margin-left: 0px;
    margin-right: 0px;
    width: 90%;
    border-radius: 10px;
    margin-top: 100px;
  }

  .addNewFormWrapper form {
    width: 95%;
  }

  .addNewFormInputWrapper input {
    width: 98%;
    height: 4.5vh;
    padding-top: 0px;
    margin-top: 0px;
  }

  .addNewFormInputWrapper label {
    font-size: 16px;
    font-weight: 400px;
  }

  .react-select-custom-styles {
    width: 99%;
  }

  .descheight input {
    height: 10vh;
  }

  .rightClass label {
    height: 21px;
    width: 50px;
  }

  .rightClass label:before {
    top: 11px;
    width: 18px;
    height: 18px;
  }

  .rightClass input:checked + label:before {
    top: 11px;
    left: 30px;
    /* border: 1.5px solid #000; */
  }

  .toggleText span {
    font-size: 12px;
  }

  .setQuickBookingSection {
    height: 4vh;
  }

  .addBtnWrapper button {
    width: 90px;
    height: 37px;
    font-size: 20px;
  }

  .descheight {
    overflow: scroll;
  }
}
