.addNewFormInputWrapper select {
  width: 845px;
  height: 50px;
  border-radius: 5px;
  border: none;
  margin: 5px 0 10px;
  color: #908c8c;
  background-color: #fff;
  border: 1px solid #3384b1;
}


/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .addNewFormInputWrapper select{
    width: 48rem;
  }
}

@media only screen and (max-width: 1025px) {
  .addNewFormInputWrapper select{
    width: 42rem;
  }
}

@media only screen and (max-width: 450px){

  .addNewFormInputWrapper select{
    width: 99%;
    height: 4.5vh;
  }

}