.profileFormWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
}
.profileFormHeaderWrapper {
  display: flex;
  align-items: center;
}
.profileFormHeader {
  display: flex;
  background-color: #3384b1;
  border-radius: 0 0 20px 20px;
  width: 350px;
  justify-content: center;
  gap: 30px;
  padding: 10px 0;
}
.profileFormHeaderWrapper span {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.formSection {
  background-color: #fff;
  display: flex;
  width: 970px;
  height: 38rem;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  position: relative;
}

/* transition css */

.profileTag {
  background-color: #3384b1;
  color: #fff;
  cursor: pointer;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.profileTag.active {
  background-color: #fff;
  color: #3384b1;
  /* width: 152px; */
  padding: 0 15px 0;
  height: 45px;
  margin: 5px 0 0px;
  border-radius: 10px;
}
.profileTag h2 {
  font-family: Lexend;
  font-size: 20px;
  font-weight: 300;
}
.profileTag svg path {
  fill: #fff;
}
.profileTag.active svg path {
  fill: #3384b1;
}
/* Css for animation */
@keyframes appear {
  0%{
    opacity: 0;
    transform: translateY(-10px);
  }
}

.formSection{
  animation: appear 350ms ease-in 1;
}
/* Css for animation ends*/
