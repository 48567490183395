.welcomeWrapper {
  display: flex;
}

.email-verify-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.p-progress-spinner-circle {
  animation: 1.5s ease-in-out infinite, black 6s ease-in-out infinite;
  stroke: #ffffff;
}
.welcomeImgDiv {
  background-image: url(../../assets/jpg/WelcomePgImg.jpg);
  background-repeat: no-repeat;
  width: 37.7%;
  height: 1080px;
  position: relative;
}

.incubatorTxt {
  width: auto;
  height: 80px;
  border-radius: 90px 0 0 90px;
  background-color: #3384b1;
  position: absolute;
  right: 3%;
  top: 105px;
}
.incubatorTxt h3 {
  font-family: Lexend;
  font-size: 40px;
  color: #fff;
  font-weight: 300;
  margin: 17px 35px;
  text-transform: capitalize;
  text-align: center;
}
.loginCredentailsWrapper {
  width: 62%;
}
.loginCredentails h2 {
  font-family: Abhaya Libre;
  font-size: 53px;
  font-weight: 400;
  color: #3384b1;
}
.loginCredentails {
  text-align: center;
  padding: 165px 0 0;
}
.inputcontainer {
  width: 60%;
  height: 80px;
  display: flex;
  align-items: center;
  background-color: #3384b1;
  border-radius: 10px;
}

.loginCredentails > img {
  width: 15rem;
  height: 15rem;
  object-fit: contain;
}

.inputcontainer svg {
  width: 10%;
}
.inputcontainer input {
  width: 95%;
  height: 95%;
  padding: 0 20px;
  outline: none;
  font-family: Inter;
  font-size: 25px;
  font-weight: 300;
  /* text-transform: capitalize; */
  border-radius: 5px;
}
.errorClass {
  margin-bottom: 50px;
  width: 65%;
}
.errorClass span {
  color: red;
  display: flex;
  margin: 1rem 5rem 0rem;
}
.loginCredentails form {
  display: flex;
  align-content: space-around;
  flex-direction: column;
  justify-content: space-evenly;
  /* row-gap: 40px; */
  align-items: center;
}
button.submitBtnClass {
  font-family: Inter;
  font-size: 36px;
  font-weight: 500;
  width: 300px;
  height: 80px;
  background-color: #3384b1;
  color: #fff;
  border-radius: 100px;
  border: none;
  cursor: pointer;
}
.forgotPasswordLink {
  width: 750px;
  text-align: right;
}
.forgotPasswordLink a {
  color: #75caea;
  font-family: Inter;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
}

/* otp modal window css */
.otpModalWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.3s;
  flex-direction: column;
}
.otpmodal {
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 20px;
  gap: 25px;
  padding: 30px 0 0 0;
  position: relative;
}

.otpBtnClass {
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.waitPassKey {
  color: #3384b1;
  text-align: center;
  width: 89%;
}

.resendText {
  color: #3384b1;
  font-weight: 700;
  cursor: pointer;
  font-size: 16px;
  font-family: Nunito;
}

.otpmodal > svg {
  position: absolute;
  top: 1%;
  right: 3%;
  cursor: pointer;
}

.otpTitle h2 {
  font-size: 29px;
  font-weight: 500;
  margin: 0;
  font-family: "Nunito";
}

.otpFieldClass {
  display: flex;
  gap: 35px;
}

.otpFieldClass input {
  width: 45px;
  height: 45px;
  border-radius: 6px;
  border: 1px solid;
  text-align: center;
  font-size: 32px;
  color: #3384b1;
}
.otpBtnClass button {
  font-family: Nunito;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  background-color: #3384b1;
  border: none;
  border-radius: 100px;
  padding: 10px 35px;
  cursor: pointer;
}

.submitBtn {
  display: none;
}

@-moz-document url-prefix() {
  .incubatorTxt {
    right: 4%;
  }
}
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1024px) {
  .inputcontainer {
    width: 90%;
  }
  .inputcontainer input {
    width: 85%;
    height: 95%;
  }
  .incubatorTxt {
    right: 0%;
  }
}

@media only screen and (max-width: 450px) {
  body {
    /* background-image: url(../../assets/jpg/WelcomePgImg.jpg); */
  }

  .welcomeWrapper {
    display: flex;
    justify-content: center;
    background-image: url(../../assets/jpg/WelcomePgImg.jpg);
    padding: 5rem 0 22.5rem;
  }

  .loginCredentails img {
    width: 60% !important;
    height: 60% !important;
  }

  .loginCredentails h2 {
    margin-top: 0%;
  }

  .welcomeImgDiv {
    display: none;
  }

  .inputcontainer {
    width: 140%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #3384b1;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  button.submitBtnClass {
    font-size: 26px;
    font-weight: 500;
    width: 200px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  .inputcontainer input {
    width: 100%;
    height: 100%;
    padding: 0 20px;
    outline: none;
    font-family: Inter;
    font-size: 25px;
    font-weight: 300;
    /* text-transform: capitalize; */
    border-radius: 10px;
    border: none;
  }

  .inputcontainer svg {
    width: 20%;
  }

  .welcomeImgDiv {
    background-image: none;
  }

  /* OTP MODAL */

  .otpModalWrapper {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .otpmodal {
    width: 90%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 20px;
    /* gap:0px; */
    justify-content: space-between;
    padding-bottom: 0px;
    padding-top: 0px;
    overflow: hidden;
  }

  .otpIconClass {
    display: none;
  }

  .otpTitle {
    /* flex:1;
    bottom: 50%;  */
    background-color: #3384b1;
    color: white;
    height: 68px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .otpTitle h2 {
    /* flex:1;
    bottom: 50%;  */
    font-size: 30px;
    font-weight: 500;
  }

  .otpFieldClass input {
    width: 4%;
    height: 50%;
    padding: 10px;
    font-size: inherit;
  }

  .otpFieldClass input:focus {
    border-color: #3384b1;
  }

  .otpFieldClass {
    gap: 0px;
    justify-content: space-evenly;
  }

  .otpBtnClass {
    padding-bottom: 10px;
  }

  .otpBtnClass button {
    border-radius: 10px;
    padding: 10px 19px;
    font-size: 24px;
  }

  .otpBtnClass button {
    width: 200px;
    height: 60px;
  }

  .verifyOtpBtn {
    display: none;
  }

  .submitBtn {
    display: block;
  }
}
