.itemWrapper{
 height: 50px;
 width: 100%;
}


.itemWrapper:hover{
    background-color: white;
}

.itemAndIcon{
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
}

.icon-wrapper{
    padding-right: 10px;
    padding-left: 20px;
    width: 20px;
    height: 77px;
    display: flex;
    align-items: center;
}

.itemWrapper .itemAndIcon .icon-wrapper svg path{
    fill: white;
}

.itemWrapper:hover .itemAndIcon .icon-wrapper svg path{
    fill: #3384b1;
}


hr{
    padding: 0px;
    margin: 0px;
    border-color: white;
}

a{
    color: white;
    text-decoration: none;
}

.itemAndIcon:hover{
    color: #3384b1;
}



