.navBarWrapper.user_requests {
  overflow: hidden;
}

.userApprovalWrapper {
  max-height: 40.54rem;
  overflow-y: auto;
}

.p-datatable .p-paginator-bottom {
  margin-bottom: 2vw;
}

.updateUserHeading {
  font-family: "Nunito";
  text-align: center;
  background: #3384b1;
  margin: 0;
  height: 53px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.updateUserHeading + svg {
  position: absolute;
  right: 3%;
  top: 2%;
}

.userApprovalWrapper {
  max-height: 51.54rem;
}
.userApprovalTable {
  border-collapse: collapse;
  width: 100%;
  /* border: 1px solid #969191; */
}
.userApprovalWrapper {
  padding: 2% 3% 0 2%;
}
.userApprovalTable th {
  font-family: Lexend;
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  background-color: #3384b1;
  color: #fff;
  padding: 20px 0;
  border-left: 1px solid #fff;
}
.userApprovalTable th:first-child {
  border-left: none;
  border-radius: 10px 0 0 0;
}
.userApprovalTable th:last-child {
  border-radius: 0 10px 0 0;
}

.userApprovalTable td {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 45px;
  border: 1px solid #969191;
  padding: 5px 0;
}

.acceptButton {
  background-color: red;
  color: white;
  padding: 3px 5px;
  border-radius: 5px;
  border: none;
}
.btnAdjustClass .pagination {
  margin-inline-end: 0rem;
}
.userApprovalMobWrapper {
  display: none;
}
/* Media Queries */
/* For desktop and laptop: 1025 X 1280 */
@media only screen and (max-width: 1280px) {
  .userApprovalWrapper {
    padding: 25px 70px 0 75px;
  }
  .btnAdjustClass .pagination {
    margin-inline-end: 4rem;
  }
}

@media only screen and (max-width: 1025px) {
  .userApprovalWrapper {
    padding: 25px 40px 0 50px;
  }
}
@media only screen and (max-width: 450px) {
  .userApprovalWrapper {
    display: none;
  }
  .userApprovalMobWrapper {
    display: block;
    margin-block-start: 8rem;
  }
  .mobUserApprovalData {
    display: flex;
    align-items: center;
    box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 20px;
    margin: 20px 10px;
    border-radius: 10px;
    padding: 10px 15px;
  }
  .userImage {
    width: 20%;
    height: 100%;
  }
  .userImage img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .userData {
    width: 80%;
    padding-left: 5px;
  }
  .userData p {
    margin: 5px 0;
    font-size: 14px;
  }
  .userData span {
    font-weight: 600;
    padding-right: 5px;
  }
  .btnAdjustClass .pagination {
    margin-inline-end: 0rem;
  }
}
